import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "timChambers",
  "categories": ["testing"],
  "date": "2021-09-30",
  "path": "/blog/test-like-you-fly-5",
  "summary": "What was your Intent? - TLYF",
  "title": "Test Like You Fly - What was your Intent?",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Test Like You Fly - What was your Intent?",
      "description": "What was your Intent? - TLYF"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In our previous installment in the TLYF series we took "time" to cover the important topic of Time and Timing. Next up, we're going to get into what we call `}<strong parentName="p">{`Intentional Code.`}</strong></p>
    <h2>{`What Was Your intent?`}</h2>
    <p>{`As crafters of code we spend our days (and sometimes nights) writing code. Code that does stuff. And the purpose we have when we generate code is to express our intent to the computer(s) that will execute the code. We do that through language. And how we express that, the assertions we make (explicit and implicit), are our way of communicating with the computer.`}</p>
    <p>{`But wait ... is the computer our only audience?`}</p>
    <p>{`Absolutely not. In fact perhaps our most important audience is our fellow developers. They will read our code. They likely will review our code. They will try to understand the INTENT of our code. So the more we communicate intent as we write code, the more chance that they, and the computer, and our future selves a few months from now, will understand precisely what was expected of that code - the input, the processing rules, the data, and subsequently the nature of the outcome. How do we best accomplish this?`}</p>
    <p>{`We must write `}<em parentName="p">{`intentional code`}</em>{`.`}</p>
    <h2>{`What makes code exude intent? Why?`}</h2>
    <p>{`As I do much of my development today in Ruby, let's use that language as the lens for this. You can certainly find similar traits in almost every computer language - both functional and object-oriented - that provide equivalent capabilities. As we craft our code to meet requirements we must constantly ask ourselves "what could go wrong?". It is the answer to that simple question that informs us as to how to express intent - both what should happen and what should never happen.`}</p>
    <p>{`Failure to accomplish implicit intent occurs most often at interfaces. Whether it is making an API request, using a framework method, or addressing a local OSS package bundled into your application, those "conversations" are fraught with uncertainty. Should I be checking for sending the right query parameters, or do I expect the callee to have that responsibility? Is the interface exchanging just simple primitive data, e.g. numeric values or strings, or is there sufficiently rich metadata to determine the class of the data sent and received for me to ensure that units of measure are compatible, or missing data is discovered?`}</p>
    <p>{`As we TLYF across longer Total Operations Chain Timeframes, we have broader intentions to verify. Satisfying a customer of an e-commerce application involves the integral activities of accepting orders, managing payment, handling shipping and confirming satisfaction. It is hundreds of details with dozens of interfaces where a gap could occur. First time activities include establishing customer profiles, capturing that first payment and ensuring initial e-mail was delivered.`}</p>
    <h3>{`Code is the reason we have code`}</h3>
    <p>{`Confused? You shouldn't be. Each line of code, each variable, each method, each class, each function - every single character - is in place for a reason. If not then it can be eliminated without impact on the outcome of the mission. Our intent then, is to craft the minimum amount of code that is necessary to fulfill the objective. How do we recognize when we have unnecessary code? Through testing. Ideally an automated style of testing referred to as "mutation testing". Mutation testing (for example `}<a parentName="p" {...{
        "href": "https://github.com/mbj/mutant"
      }}>{`mutant`}</a>{`) simply runs our tests, then mutates our code in small but powerful ways, replacing or removing code, and then rerunning the tests. If the tests still pass - especially after removal of code - than one of two inferences can be drawn. One, that code is unnecessary to the overall objective. It is removable. Or two, the tests confirming the need for that code are insufficient to confirm that code. In the latter case the tests (or possibly the strictness of the code) need to be enhanced.`}</p>
    <h3>{`Ensure Immutability when handling Data`}</h3>
    <p>{`Stateless code - or code that at least encapsulates the state as locally as possible for as short a period as possible - is easier to reason about and ensure correctness. Code that has no "side-effects" is code we can predict.`}</p>
    <p>{`Ensuring data that should not change during execution does not change is ideal (`}<a parentName="p" {...{
        "href": "https://github.com/dkubb/adamantium"
      }}>{`Adamantium`}</a>{`)`}</p>
    <ul>
      <li parentName="ul">{`Use CONSTANTS whenever possible for static content of all types - freeze all constants as deeply as possible to ensure inadvertent alteration is surfaced`}</li>
      <li parentName="ul">{`Freeze results as often as possible to prevent inadvertent alteration when passing upstream (`}<a parentName="li" {...{
          "href": "https://github.com/dkubb/ice_nine"
        }}>{`IceNine`}</a>{`)`}</li>
      <li parentName="ul">{`Never alter input parameters - duplicate input if needed to sanitize. This prevents hidden side-effects in OOP where state is mysteriously altered`}</li>
      <li parentName="ul">{`All database requests that are intended purely for read-only - no update - processing should be made through readonly channels or declared explicitly`}</li>
    </ul>
    <h3>{`Surface errors (yours and others) as you write your code`}</h3>
    <p>{`We all have a favorite IDE or editor that focuses our thoughts as we write. And depending upon whether your language is statically or dynamically typed, compiled or interpreted, you always have the choice to convey through your code syntactic correctness.`}</p>
    <p>{`Use getter access (`}<inlineCode parentName="p">{`abc`}</inlineCode>{`) rather than instance variable access (`}<inlineCode parentName="p">{`@abc`}</inlineCode>{`) to ensure compile-time fails`}</p>
    <ul>
      <li parentName="ul">{`A mistyped instance variable is always just nil and becomes invisible`}</li>
      <li parentName="ul">{`Private `}<inlineCode parentName="li">{`attr_reader`}</inlineCode>{`'s provide simple mechanisms for getter method generation`}</li>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "https://github.com/mbj/concord"
        }}>{`Concord`}</a>{` gem to generate methods for initialization parameters`}</li>
    </ul>
    <p>{`Don't just expect - DEMAND - a particular outcome using the strongest assertive methods possible`}</p>
    <ul>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`fetch(:key)`}</inlineCode>{` for `}<inlineCode parentName="li">{`Hash`}</inlineCode>{` access rather than `}<inlineCode parentName="li">{`[:key]`}</inlineCode>{` to communicate intent of key presence`}</li>
      <li parentName="ul">{`When in Rails use bang methods like `}<inlineCode parentName="li">{`first!`}</inlineCode>{`, `}<inlineCode parentName="li">{`last!`}</inlineCode>{`, `}<inlineCode parentName="li">{`find_by!`}</inlineCode>{` where content presence/existence is required`}
        <ul parentName="li">
          <li parentName="ul">{`In specs this makes failure clear vs. typical `}<inlineCode parentName="li">{`NoMethodError`}</inlineCode>{` on `}<inlineCode parentName="li">{`NilClass`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <p>{`Prove that your intent is met`}</p>
    <ul>
      <li parentName="ul">{`Ensure files transmitted/stored in the cloud are received/persisted`}</li>
      <li parentName="ul">{`Test that results are in fact immutable`}</li>
    </ul>
    <h2>{`What does writing intentional code buy us?`}</h2>
    <p>{`Excellent question. It allows us to more clearly reason about the code. Thus...`}</p>
    <ul>
      <li parentName="ul">{`The code communicates more precisely and deeply what we expect in production AND while testing`}</li>
      <li parentName="ul">{`The system(s) executing code has the ability to detect more unexpected conditions`}</li>
      <li parentName="ul">{`We discover earlier in the development cycle where our expectations are violated`}</li>
      <li parentName="ul">{`Our assertions become codified and operate in the production environment`}</li>
      <li parentName="ul">{`Our intentions become visible for others to review, affirm and abide by now and in the future`}</li>
      <li parentName="ul">{`And when our intent is violated the location is often far more localized and concise around the issue than when the violation is discovered far away in a prior or future method/module`}</li>
      <li parentName="ul">{`As requirements change we can clearly see what intents need to be altered or relaxed to accommodate the inevitable`}</li>
    </ul>
    <p>{`Write with intent.`}</p>
    <p>{`Next up in our TLYF Series → Logging and Failure Modes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      